// -----------------------------------------------------------------------------
// This file contains variables
// -----------------------------------------------------------------------------

// Fonts
$base-font-family: "Inter", sans-serif;
$base-font-size: 16px;
$base-font-weight: 400;

// Colors
$base-color: #fff;
$base-color--dark: #f9fafb;
$text-color: #141b2d;
$text-color--grey: #6b7280;
$text-color--grey-light: #9ca3af;
$text-color--light: #fff;
$primary-color: #0b4bff;
$secondary-color: #00c2ff;
$tertiary-color: #90e4ff;
$grey-bg: #f9fafb;
$border-color: #f3f4f6;
$border-color--secondary: #e5e7eb;
$black: #000;
$white: #fff;
$red: #b91010;

$min-vw: 320px;
$max-vw: 1400px;

// Grid
$grid-columns: 60;
$modal-fade-transform: scale(0.8);
