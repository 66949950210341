// -----------------------------------------------------------------------------
// This file contains basic styles
// -----------------------------------------------------------------------------

html {
    overflow-x: hidden;
}

body {
    font-family: $base-font-family;
    font-weight: $base-font-weight;
}

a {
    color: $text-color;
    text-decoration: none;
    transition: color 0.3s ease;
}

a,button {
    transition: background-color .2s ease-in-out, opacity .2s ease-in-out,box-shadow .2s ease-in-out,color .2s ease-in-out;
}

ul {
    margin-bottom: 0;
}

button {
    background: none;
    border: none;
    outline: none;
}

h1,h2,h3,h4,h5,h6 {
    margin: 0;
    color: $black;
}

p {
    color: $text-color--grey;
}

.container {
    position: relative;
    z-index: 2;
}

.edge-shapes {
    position: absolute;
    width: 10vw;
    z-index: 1;
    @include media-breakpoint-down(sm) {
        width: 43vw;
        opacity: .05;
    }
    &.left-shapes {
        left: 0;
    }
    &.right-shapes {
        right: 0;
    }
}

.grey-bg {
    background-color: #F9FAFB;
}

section {
    position: relative;
}

section:not(.hero) {
    padding: 130px 0;
    @include media-breakpoint-down(lg) {
        padding: 80px 0;
    }
    h3 {
        font-weight: 800;
        @include fluid-font($min-vw,$max-vw, 23px,36px);
        letter-spacing: -1px;
    }

    h4 {
        font-weight: 500;
        font-size: rem(18px);
        color: #111827;
    }

    .subtitle {
        max-width: 768px;
        margin: 20px auto 32px auto;
        @include fluid-font($min-vw,$max-vw,16px,18px);
        line-height: 28px;
        font-weight: 400;
        color: $text-color--grey;
        text-align: center;
    }

}

.hidden {
    position: fixed;
    left: -100rem;
    bottom: -100rem;
}


.word-break {
    &--word {
        word-break: break-word;
    }

    &--all {
        word-break: break-all;
    }

    &--keep-all {
        word-break: keep-all;
    }
}

.icon-wrap {
    color: $text-color--grey;

    &:hover {
        cursor: pointer;
        color: darken($text-color--grey, 10%);
    }
}
