.base-btn {
    height: 62px;
    padding: 0 40px;
    border-radius: 6px;
    font-weight: 500;
    font-size: rem(18px);
    color: $white;
    background-color: $primary-color;
    box-shadow: 0 1px 3px 0 rgba($black,0.1);
    &:hover {
        background-color: darken($primary-color,10%);
    }

    &.btn-shadow {
        background-color: $white;
        color: $primary-color;
        &:hover {
            box-shadow: 0 5px 8px 0 rgba($black,0.1);
        }
    }

    &.small-btn {
        height: 42px;
        font-size: rem(16px);
        padding: 0 17px;
    }

    &.border-btn {
        font-size: rem(14px);
        padding: 5px;
        height: auto;
        background-color: $white;
        color: $primary-color;
        border: 1px solid $primary-color;
        box-shadow: none;

        svg {
            fill: $primary-color;
        }
    }
}
