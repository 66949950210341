/**
   HERO SECTION
 */

.hero {
    padding: 130px 0 118px 0;
    background-color: $grey-bg;
    position: relative;

    svg {
        width: 100%;
        max-width: 634px;
    }
    h1 {
        font-weight: 800;
        @include fluid-font($min-vw,$max-vw,32px,60px);
        letter-spacing: -1.7px;
        line-height: 1;
        @media (min-width: 800px) {
            opacity: 0;
        }
    }

    h2 {
        max-width: 768px;
        margin: 20px auto 32px auto;
        @include fluid-font($min-vw,$max-vw,18px,20px);
        line-height: 28px;
        font-weight: 400;
        color: $text-color--grey;
        @media (min-width: 800px) {
            opacity: 0;
        }
    }

    .left-shapes {
        top: 20%;
    }

    .right-shapes {
        bottom: 13%;
    }

    .base-btn {
        @media (min-width: 800px) {
            opacity: 0;
        }
    }
}

/**
    HOW IT WORKS
 */

#how-it-works {
    h4 {
        margin: 20px 0 8px 0;
    }
}

/**
    BENEFITS
 */

#benefits {
    .left-shapes {
        bottom: -46px;
    }
    h6 {
        font-weight: 500;
        font-size: rem(18px);
        color: #111827;
        margin-bottom: 8px;
    }

    .benefit-box {
        padding-left: 30px;
        position: relative;
        &:before {
            content: " ";
            width: 14px;
            height: 13px;
            background: url("/img/green-check.svg");
            position: absolute;
            top: 4px;
            left: 0;
        }
    }
}

/**
    RESULTS
 */

#results {
    h3 {
        margin-bottom: 20px;
    }

    .result-card {
        width: 100%;
        height: 100px;
        background-color: $white;
        border-radius: 8px;
        box-shadow: 0 1px 3px 0 rgba($black, 0.1);
        padding: 0 24px;
        img {
            margin-right: 20px;
        }

        small {
            display: block;
            font-weight: 500;
            font-size: rem(14px);
            color: #6b7280;
            line-height: 1;
            margin-bottom: 3px;
        }

        strong {
            font-weight: 600;
            font-size: rem(24px);
            line-height: 1.3;
            span {
                display: block;
                font-size: rem(14px);
            }
        }

        .status {
            display: block;
            padding-left: 15px;
            font-weight: 600;
            font-size: rem(14px);
            color: #059669;
            position: relative;
            margin-left: 15px;
            &:before {
                content: " ";
                width: 10px;
                height: 12px;
                background: url("/img/arrow-up.svg");
                position: absolute;
                left: 0;
                top: 4px;
            }
        }
    }
}

/**
    CALCULATE PROFIT
 */

.calculate-profit {
    padding-bottom: 355px !important;
    h4 {
        max-width: 592px;
    }

    .right-shapes {
        top: -50px;
    }
}

/**
    REVIEWS
 */

#reviews {
    margin-top: -240px;
    .right-shapes {
        bottom: 0;
        width: 17vw;
        @include media-breakpoint-down(sm) {
            width: 80vw;
        }
    }
    .review-box {
        border-radius: 12px;
        background-image: url("/img/review-box-bg-img.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-color: $primary-color;
        background-blend-mode: multiply;

        .testimonials-swiper {
            .swiper-slide {
                padding: 148px 64px;

                @include media-breakpoint-down(sm) {
                    padding: 80px 30px;
                }
                //&:hover {
                //    cursor: pointer;
                //}
            }
        }

        .review-box-wrap {
            .h2, .h3, .h4, .h5 {
                color: $white;
                font-weight: 500;
                font-size: rem(24px);
                max-width: 528px;
                margin-bottom: 20px;
            }

            p, small {
                display: block;
                font-weight: 500;
                font-size: rem(16px);
                color: $white;
            }
        }
    }
}

/**
    FAQ
 */

#faq .left-shapes {
    top: -142px;
}
.faq-wrap {
    max-width: 768px;
    margin: 30px auto 0 auto;
    border-top: 2px solid #e5e7eb;
    button {
        width: 100%;
        padding: 24px 30px 24px 0;
        text-align: left;
        position: relative;
        &:after {
            content: " ";
            width: 16px;
            height: 9px;
            background: url("/img/arrow-down.svg") no-repeat;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            transition: transform 0.2s ease-in-out;
            transform-origin: top;
        }
        &[aria-expanded="true"]:after {
            transform: rotate(180deg) translateY(0);
        }
    }

    .collapse-wrap {
        border-bottom: 1px solid #e5e7eb;
        &:last-of-type {
            border-bottom: none;
        }
    }

    p {
        text-align: left;
        padding-right: 30px;
    }
}

/**
    CONTACT US
 */

#contact-us {
    .left-side {
        background-color: #f1f3f5;
        height: 100%;
        padding: 122px 80px 204px 0;
        position: relative;
        -webkit-font-smoothing: antialiased;
        @include media-breakpoint-down(lg) {
            padding: 40px !important;
            height: auto !important;
        }
        &:before {
            content: " ";
            width: 9999px;
            height: 100%;
            background-color: #f1f3f5;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 100%;
            @include media-breakpoint-down(lg) {
                display: none;
            }
        }
        p {
            margin-bottom: 0;
        }
        h3 {
            margin-bottom: 7px;
        }

        ul li {
            margin-bottom: 8px;
        }

        a {
            color: #6b7280;
            img {
                margin-right: 10px;
            }
        }
    }

    .right-side {
        height: 100%;
        background-color: #f9fafb;
        padding: 96px 0 84px 105px;
        position: relative;
        @include media-breakpoint-down(lg) {
            padding: 40px !important;
            height: auto !important;
        }
        &:before {
            content: " ";
            width: 9999px;
            height: 100%;
            background-color: #f9fafb;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 100%;
            @include media-breakpoint-down(lg) {
                display: none;
            }
        }

        .form-group {
            margin-bottom: 24px;
            input {
                width: 100%;
                height: 50px;
                border-radius: 6px;
                border: 1px solid #d1d5db;
                outline: none;
                padding: 0 20px;
                &:focus {
                    border-color: $primary-color;
                }
            }

            textarea {
                width: 100%;
                height: 122px;
                border-radius: 6px;
                border: 1px solid #d1d5db;
                outline: none;
                padding: 20px;
                &:focus {
                    border-color: $primary-color;
                }
            }
        }
    }
}

#trading-chart-app {
    aspect-ratio: 1/1;
    #tradingview-chart {
        height: 100%;
    }
}

/**
    DASHBOARD
 */
#results {
    .result-card {
        .currency {
            color: #000;
            font-weight: 500;
            display: inline;
        }
    }

    .base-btn {
        height: 48px;
        padding: 0 20px;
        margin-left: 20px;
        box-shadow: 0 4px 4px 0 rgba($black, 0.25);
    }

    input {
        height: 48px;
        width: 155px;
        text-align: center;
        border: none;
        background: $white;
        border-radius: 8px;
        outline: none;
        box-shadow: 0 4px 4px 0 rgba($black, 0.25);
        cursor: pointer;
        font-size: rem(20px);
        font-weight: 600;
    }

    #trading-chart-app {
        aspect-ratio: inherit !important;
    }
}

/**
    Affiliate Program
 */
.white-box {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%);
    .box-content {
        padding: 3rem 10rem;
    }
    .percentage {
        color: $primary-color;
        font-weight: 700;
    }
    input[type="text"],
    input[type="number"] {
        border-radius: 8px;
        background-color: #f1f3f5;
        border-color: $primary-color;
        color: #6b7280;
        padding: 8px 20px;
        box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%);
    }
    .dashboard-btn {
        height: 45px !important;
    }
    .table {
        color: $text-color--grey;
    }
    .thead {
        td {
            font-weight: 600;
        }
    }
    tr {
        border-bottom: 1px solid $text-color--grey;
    }
    td {
        text-align: right;
        border-bottom: none;
    }
    td:first-child {
        text-align: left;
    }
}
/**
    Transfer
 */
.white-box {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%);
}
#dashboard-form {
    label {
        display: block;
        color: $text-color--grey;
        padding: 0.6rem 0 0 0;
    }
    input {
        border-radius: 8px;
        background-color: #f1f3f5;
        color: #6b7280;
        padding: 0 20px;
        box-shadow: 0 4px 4px 0 rgba($black, 0.25);
        width: 100%;
        height: 48px;
        border: 1px solid $primary-color;
        outline: none;
    }

    select {
        width: 100%;
        border-radius: 8px;
        background-color: #f1f3f5;
        padding: 0 20px;
        border: none;
        height: 48px;
        cursor: pointer;
        outline: none;
        color: #6b7280;
        background-image: url("/img/arrow-down-select.svg");
        background-repeat: no-repeat;
        background-position: right 17px top 20px;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        &::-ms-expand {
            display: none;
        }
    }

    .base-btn {
        height: 48px;
        padding: 0 20px;
    }
    .info {
        border-radius: 50%;
        color: $primary-color;
        font-weight: 700;
        width: 100%;
        display: block;
    }

    .x-btn {
        color: $red;
    }

    table {
        td:nth-of-type(2),
        td:nth-of-type(3) {
            text-align: center;
        }
    }

    table tr:last-of-type {
        border-bottom: none;
        td {
            border-bottom-width: 0;
        }
    }
    h3 {
        font-size: rem(25px);
    }
}
