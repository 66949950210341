footer {
    padding: 48px 0;
    ul {
        a {
            color: #6B7280;
        }
    }
    p {
        margin-bottom: 0;
    }
}
