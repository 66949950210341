header {
    padding: 22px 0;
    transition: all .2s ease-in-out;
    &.smaller {
        background-color: $white;
        padding: 10px 0;
        box-shadow: 0 0 7px 0 rgba($black, .15);
    }

    &.hide {
        transform: translateY(-100%);
    }
    nav {
        @media (max-width: 992px) {
            width: 100vw;
            min-height: 100vh;
            position: fixed;
            top: 0;
            left: 100%;
            right:0;
            bottom: 0;
            background-color: $primary-color;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            transition: left .2s ease-in;
            z-index: 999;
            &.open {
                left: 0;
            }
            ul a {
                color: $white !important;
                font-size: rem(20px);
            }
        }
        ul {
            li.active {
                a {
                    color: $primary-color;
                }
                a:after {
                    width: 100%;
                }
            }
            a {
                color: $text-color--grey;
                position: relative;
                &:after {
                    content: " ";
                    width: 0;
                    height: 1px;
                    background-color: $primary-color;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    transition: width .2s ease-in;
                }
                &:hover {
                    color: $primary-color;
                    &:after {
                        width: 100%;
                    }
                }
            }
        }
    }

    label[for="menu-opener"] {
        display:flex;
        flex-direction:column;
        width:43px;
        cursor:pointer;
        position: relative;
        z-index: 1000;
        span {
            background: $black;
            border-radius:10px;
            height:4px;
            margin: 4px 0;
            transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);

        }
        span:nth-of-type(1){
            width:50%;

        }

        span:nth-of-type(2){
            width:100%;
        }


        span:nth-of-type(3){
            width:75%;

        }


        input[type="checkbox"]{
            display:none;
            &:checked ~ span  {
                background: $white;
            }
        }


        input[type="checkbox"]:checked ~ span:nth-of-type(1){
            transform-origin:bottom;
            transform:rotatez(45deg) translate(5px,0px)
        }


        input[type="checkbox"]:checked ~ span:nth-of-type(2){

            transform-origin:top;
            transform:rotatez(-45deg)
        }


        input[type="checkbox"]:checked ~ span:nth-of-type(3){

            transform-origin:bottom;
            width:50%;
            transform: translate(19px,-6px) rotatez(45deg);

        }

    }



}

