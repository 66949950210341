.modal-content {
    border: none;
    .modal-body {
        padding: 40px 20px;
        .btn-close {
            position: absolute;
            top: 15px;
            right: 15px;
        }
    }
    h3 {
        font-weight: 700;
    }
    .form-group {
        text-align: left;
        margin-top: 10px;
        label {
            display: block;
        }

        input {
            width: 100%;
            height: 50px;
            border: 1px solid rgb(188, 188, 188);
            border-radius: 6px;
            outline: none;
            background: none;
            padding: 0 15px;
            &:focus {
                border-color: $primary-color;
            }
        }

        select {
            width: 100%;
            height: 50px;
            border: 1px solid rgb(188, 188, 188);
            border-radius: 6px;
            outline: none;
            background: none;
            padding: 0 15px;
            &:focus {
                border-color: $primary-color;
            }
        }

        button[type="submit"] {
            margin: 10px 0;
        }

        button[type="button"] {
            text-decoration: underline;
            color: rgb(130, 130, 130);
        }

        .btn-secondary {
            text-decoration: none !important;
            color: $primary-color;
            margin: 10px 0;
        }

    }
}

.modal-dialog {
    max-width: 600px !important;
}
